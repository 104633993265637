.active-chat-card {
    align-items: center;
    background-color: var(--bg-300);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    padding: 15px;
    text-align: left;
    transition: 0.5s;
}

.active-chat-card:hover {
    margin-left: 10px;
    margin-right: -10px;
}

.active-chat-name {
    font-weight: 600;
    font-size: 20px;
}

.active-chat-right {
    text-align: right;
}

.active-chat-last-msg {
    background-color: var(--bg-100);
    border-radius: var(--msg-right-border-radius);
    font-size: 16px;
    font-weight: 300;
    float: right;
    margin-top: 5px;
    max-width: 400px;
    overflow: hidden;
    padding: 6px 10px;
    text-overflow: ellipsis;
    width: fit-content;
    white-space: nowrap;
}

.active-chat-last-msg-sent {
    background-color: var(--blue);
}

.active-chat-timestamp {
    color: var(--text-gray);
    font-size: 13px;
}