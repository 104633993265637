@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;500;700&display=swap');

:root {
    --bg-400: #121521;
    --bg-300: #171a29;
    --bg-200: #212540;
    --bg-100: #282d42;
    --bg-op-100: rgba(40, 45, 66, 0.4);
    --blue: #1d9bf5;
    --green: #36e06c;
    --red: #f52043;
    --border-radius: 5px;
    --msg-padding: 6px 10px;
    --msg-left-border-radius: 12px 12px 12px 0;
    --msg-right-border-radius: 12px 12px 0 12px;
    --text: #fff;
    --text-gray: gray;
    --font: 'Merriweather Sans', sans-serif;
    --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: var(--bg-300);
    border-radius: 15px;
    cursor: pointer;
    transition: 0.3s;
}

html, body, #root, .app {
    height: 100%;
}

body {
    background-color: var(--bg-200);
    color: var(--text);
    font-family: var(--font);
}

form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

a {
    color: var(--text);
    text-decoration: none;
}

.flex-row {
    display: flex;
}

.align-center {
    align-items: center;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.gray {
    color: var(--text-gray);
}

.empty-error {
    color: var(--text-gray);
    padding: 25px 0;
    text-align: center;
}

.blue-link {
    color: var(--blue);
}

.blue-link:hover {
    text-decoration: underline;
}

.message-sent {
    background-color: var(--blue) !important;
}