.welcome-page {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    text-align: center;
    padding: 50px 10px;
}

.welcome-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.welcome-container {
    background-color: var(--bg-100);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    margin: 25px;
    padding: 25px 50px;
    width: fit-content;
}

.welcome-button {
    margin: 10px 0 0 0;
    width: 100%;
}