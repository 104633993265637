.chat-flex {
    display: flex;
}

.chat-container {
    padding: 25px;
    transition: 0.8s ease;
    width: 100%;
}

.chat-header {
    background-color: var(--bg-300);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
}

.chat-header-big {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 35px;
}

.chat-header-right {
    text-align: right;
}

.chat-at-icon {
    color: var(--text-gray);
    margin-right: 5px;
    width: 40px;
}

.chat-account-icon {
    color: var(--blue);
    margin-right: 5px;
    width: 50px;
}

.failed-container {
    margin: auto;
    margin-top: 50px;
    max-width: 400px;
    text-align: center;
}

.failed-header {
    font-size: 80px;
    font-weight: 700;
}

.failed-description {
    color: var(--text-gray);
    font-size: 18px;
    line-height: 150%;
}

@media screen and (max-width: 550px) {
    .chat-header-right {
        display: none;
    }
}