* {
    margin: 0;
}

header {
    background-color: var(--bg-400);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
    padding: 8px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 1;
}

.header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: auto;
    font-weight: 700;
    font-size: 25px;
}

.header-title:hover .header-img {
    transform: rotate(360deg);
}

.header-img {
    height: 30px;
    margin-right: 8px;
    transition: 0.5s;
}

.header-link {
    height: 46px;
}

.github-img {
    border-radius: 50%;
    margin: 0;
    padding: 8px;
}

.github-img:hover {
    background-color: var(--bg-200);
}