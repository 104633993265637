.active-chats-container {
    padding: 40px 20px;
    max-width: 600px;
    margin: auto;
    text-align: center;
}

.account-info {
    align-items: center;
    background-color: var(--bg-300);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    text-align: left;
}

.account-info-big {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 35px;
    font-weight: 700;
}

.account-icon {
    color: var(--blue);
    margin-right: 8px;
    width: 60px;
}

.account-info-expiration-container {
    color: var(--text-gray);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
}

.account-info-clock-icon {
    height: 18px;
    width: 18px;
    margin-right: 5px;
}

.logout-button {
    background-color: var(--red);
}

.new-chat-container {
    margin-top: 15px;
}